<template>
  <mobile-screen
    :header="true"
    screen-class="resources-screen gray-bg icon-app1"
  >
    <template v-slot:header>
      <top-header-menu-wrapper menu-class="resource-header icon-hea1">
        <template v-slot:left>
          <div @click="returnToPreviousPage" style="cursor: pointer;">
            <icon icon="#cx-hea1-arrow-left" />
          </div>
        </template>
        <div class="component-title">
          {{ displayLabelName("services.service-types.service-types") }}
        </div>
      </top-header-menu-wrapper>
    </template>
    <ul class="clebex-item-section pill">
      <li
        class="clebex-item-section-item"
        v-for="serviceType in listOfResourceServiceTypes"
        :key="serviceType.id"
      >
        <button
          class="clebex-item-content-wrapper"
          @click="clickAction(serviceType)"
        >
          <span class="label">
            <span class="highlight">{{ serviceType.name }}</span>
          </span>
          <span class="follow-up-icons">
            <span class="follow-up-icon-item" v-if="checked(serviceType)">
              <icon icon="#cx-app1-checkmark"></icon>
            </span>
          </span>
        </button>
      </li>
    </ul>
    <button type="submit" style="display: none;" ref="submitFormBtn">
      Submit form
    </button>
  </mobile-screen>
  <router-view />
</template>

<script>
import MobileScreen from "@/layouts/MobileScreen";
import TopHeaderMenuWrapper from "@/components/global/TopHeaderMenuWrapper";
import { mapActions, mapState } from "vuex";

export default {
  name: "GetServiceResources",
  data() {
    return {
      searchQuery: "",
      submitFormState: false
    };
  },
  created() {
    this.setResourceServiceTypes(null);
    this.getResourceServiceTypes(this.$route.params.order_resource_id);
  },
  computed: {
    ...mapState("service", [
      "serviceResources",
      "serviceResource",
      "resourceServiceTypes",
      "serviceOrderType"
    ]),
    routeParam() {
      return this.$route.params.order_resource_id;
    },
    orderStatus() {
      if (this.serviceOrder && this.serviceOrder.data.service_status) {
        return this.serviceOrder.data.service_status;
      }
      return "";
    },
    listOfResourceServiceTypes() {
      if (this.resourceServiceTypes && this.resourceServiceTypes.data) {
        return this.resourceServiceTypes.data;
      }

      return null;
    }
  },
  watch: {
    routeParam() {
      if (this.routeParam) {
        this.setResourceServiceTypes(null);
        this.getResourceServiceTypes(this.$route.params.order_resource_id);
      }
    }
  },
  methods: {
    ...mapActions("service", [
      "getServiceResources",
      "setServiceResource",
      "getResourceServiceTypes",
      "setResourceServiceTypes",
      "setServiceOrderType"
    ]),
    checked(serviceType) {
      return this.$route.params.order_type_id == serviceType.id;
    },
    returnToPreviousPage() {
      this.setServiceResource(null);
      this.$router.push({
        name: this.backLinkName,
        params: this.$route.params
      });
    },
    clickAction(serviceType) {
      this.setServiceOrderType(serviceType.id);
      this.$router.push({
        name: "r_services-resources-add-order",
        params: {
          order_type_id: serviceType.id
        }
      });
    }
  },
  props: {
    backLinkName: {
      type: String,
      required: true
    }
  },
  components: {
    MobileScreen,
    TopHeaderMenuWrapper
  }
};
</script>
<style>
.order-level {
  color: #999999;
  font-size: 0.9375rem;
  line-height: 1.26667em;
  letter-spacing: -0.36px;
  margin-left: 1rem;
  margin-bottom: 5px !important;
}
</style>
